export function getLoginUrl(redirectTo?: string) {
  return redirectTo
    ? `/api/login?returnTo=${encodeURIComponent(redirectTo)}`
    : `/api/login`;
}

export function getSignUpUrl(redirectTo?: string) {
  return redirectTo
    ? `/api/login?signup=true&returnTo=${encodeURIComponent(redirectTo)}`
    : `/api/login?signup=true`;
}
