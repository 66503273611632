import {
  MutationHookOptions,
  MutationTuple,
  OperationVariables,
  QueryHookOptions,
  QueryResult,
  useMutation,
  useQuery,
} from '@apollo/client';
import {LoadingBackdrop} from '@app-components/LoadingBackdrop';
import {useRefetch} from '@app-lib/apollo/useRefetch';
import {getLoginUrl} from '@app-lib/auth0/loginUrl';
import {DocumentNode} from 'graphql';
import {
  get,
  noop,
} from 'lodash';
import ErrorPage from 'next/error';
import Router from 'next/router';
import React, {MutableRefObject} from 'react';


export function AuthQueryPage({queryOptions, children}) {
  // @ts-ignore
  const {loading, error, ...rest} = useAuthQuery(...queryOptions);

  if (loading) return (
    <LoadingBackdrop />
  );
  if (error) return (
    <ErrorPage
      title={error.message}
      statusCode={500}
    />
  );

  return children({...rest});
}

export type AuthQueryResult<TData, TVariables> =
  & Omit<QueryResult<TData, TVariables>, "refetch">
  & {
  refetch: MutableRefObject<QueryResult<TData, TVariables>["refetch"] | undefined>
}

export function useAuthQuery<TData = any, TVariables = OperationVariables>(
  query: DocumentNode,
  options?: QueryHookOptions<TData, TVariables>,
): AuthQueryResult<TData, TVariables> {
  const result = useQuery<TData, TVariables>(query, options);
  const refetchRef = useRefetch(result.refetch);
  (result as unknown as AuthQueryResult<TData, TVariables>).refetch = refetchRef;
  return withResponse(result);
}

export function useAuthMutation<TData = any, TVariables = OperationVariables>(
  mutation: DocumentNode,
  options?: MutationHookOptions<TData, TVariables>,
): MutationTuple<TData, TVariables> {
  const result = useMutation(mutation, options);
  return withResponse(result);
}

export function withResponse(result) {
  const {loading, error} = result;

  if (loading) {
    return result;
  }

  if (error) {
    if (get(error, 'networkError.statusCode') === 401) {
      // TODO: set up better auto-retry logic here after silently refreshing login somehow.
      // TODO: need a way to redirect back to the current page, globally.
      Router.push(getLoginUrl()).catch(noop);
      // continue to show loading on page/component with the query until the redirect to login works.
      result.redirect = true;
      result.loading = true;
      return result;
    }
  }

  return result;
}
