import {QueryResult} from '@apollo/client';
import {isFunction} from 'lodash';
import {
  MutableRefObject,
  useEffect,
  useRef,
} from 'react';


export function useRefetch<TData, TVariables>(
  refetch: QueryResult<TData, TVariables>["refetch"] | undefined,
): MutableRefObject<QueryResult<TData, TVariables>["refetch"] | undefined> {
  const refetchRef = useRef<QueryResult<TData, TVariables>["refetch"] | undefined>();
  useEffect(() => {
    if (isFunction(refetch) || !refetch) {
      refetchRef.current = refetch;
    }
  }, [refetch]);
  return refetchRef;
}
